<template>
	<div>
		<div class="a-profile-subpage-title -favourites">
			<div class="heading">
				<h1>{{ $t('Obľúbené') }}</h1>
				<p>{{ isAdmin ? $t('Váš výber zo všetkých kurzov a sérií') : $t('Tvoj výber zo všetkých kurzov a sérií') }}</p>
			</div>
			<div class="z-icon-wrapper -large">
				<svg-heart-icon class="w-color-secondary -heart" />
			</div>
		</div>
		<b-tabs>
			<b-tab :title="$t('Zoznam kurzov a sérií')" active>
				<w-loadable class="mt-sm-5 mt-3" :items="starredCourses" :msgEmpty="emptyMessage" />
				<b-row class="mt-sm-5 mt-3"> 
					<b-col v-for="(course, i) in starredCourses" :key="i" md="6" class="d-flex col-xxl-4">
						<z-course-card v-if="course._model == 'course'" :course="course" />
						<z-path-card v-else :path="course" />
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { mapGetters } from 'vuex'

const api = {
	starredCourses: () => wAxios.get_auth_data(`v1/auth/starred`),
	starredPaths: () => wAxios.get_auth_data(`v1/auth/starred/paths`)
}

export default {
	layout: 'profile',

	components: {
		'z-path-card': 								() => import('@/plugins/lib@course/path/card/z-path-card.vue'),
		'svg-heart-icon': () => import('@/plugins/appzmudri/_theme/icon/heart.svg?inline')
	},

	data() {
		return {
			starredCourses: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		},
		emptyMessage() {
			return this.isAdmin ? 'Nemáte žiadne obľúbené kurzy alebo série.' : 'Nemáš žiadne obľúbené kurzy alebo série.'
		}
	},

	async created() {
		try {
			let starredCourses = await api.starredCourses()
			let starredPaths = await api.starredPaths()

			starredCourses = starredCourses.map(course => {
				course._url = `/kurz/${course.slug || course.id}`
				course._model = 'course'
				return course
			})
			starredPaths = starredPaths.map(path => {
				path._url = `/seria/${path.slug || path.id}`
				path._model = 'path'
				return path
			})
			this.starredCourses = starredCourses.concat(starredPaths)
		} catch (error) {
			this.starredCourses = {error: 'Nepodarilo sa načítať Moje kurzy...'}
		}
	}
}
</script>